import type { Event, ProgramEvent, EventCard } from "~/types/events";
import { EVENT_TYPES } from "~/types/events";
import type { ItemRelation } from "~/utils/crystallize";
import type { Crystallize } from "~/crystallize/spec";
import { max, isPast } from "date-fns";

export function toEvent(item: ItemRelation): Event {
  const event = createDocument<Event>(item, {
    eventType: "themed",
    dates: [],
    program: [],
    guestsWithBio: [],
    guestsWithoutBio: [],
    fullBooked: false,
  });

  if (item.components) {
    for (const component of item.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<"arrangement">;

      switch (id) {
        case "tittel":
          event.title = getSingleLine(component);
          event.slug.custom = slugify(event.title);
          break;

        case "intro":
          event.intro = getRichText(component);
          break;

        case "datoer": {
          for (const [date] of getRepeatableChunks(component)) {
            if (date) {
              event.dates.push(getDatetime(date));
            }
          }
          break;
        }

        case "beskrivelse":
          event.description = getParagraphCollection(component);
          break;

        case "coverbilde": {
          const coverImage = getSingleImage(component, 1366);
          if (coverImage) {
            event.cover = { img: coverImage.img, alt: coverImage.alt };
          }
          break;
        }

        case "arrangementstype": {
          const selection = getSelections(component)[0];
          const eventType = EVENT_TYPES.find((it) => it === selection);
          if (eventType) {
            event.eventType = eventType;
          }
          break;
        }

        case "klokkeslett": {
          const [start, end] = getSingleChunks(component);
          const startTime = start && getSingleLine(start);
          const endTime = end && getSingleLine(end);
          event.time =
            endTime && endTime.length < 1
              ? startTime
              : startTime + " - " + endTime;
          break;
        }

        case "nettsiden":
          event.website = getSingleLine(component);
          break;

        case "fullbooket":
          event.fullBooked = getBoolean(component);
          break;

        case "lokasjon": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            event.location ??= {};

            const text = getSingleLine(chunk);

            const id = chunk.id as Crystallize.ChunkId<
              "arrangement",
              "lokasjon"
            >;

            switch (id) {
              case "by":
                event.location.city = text;
                break;

              case "sted":
                event.location.address = text;
                break;

              case "google-maps-link":
                event.location.mapLink = text;
                break;
            }
          }
          break;
        }

        case "program": {
          const repeatableChunks = getRepeatableChunks(component);
          for (const programChunks of repeatableChunks) {
            const program: ProgramEvent = {};
            for (const chunk of programChunks) {
              if (!chunk.content) {
                continue;
              }

              const id = chunk.id as Crystallize.ChunkId<
                "arrangement",
                "program"
              >;

              switch (id) {
                case "klokkeslett":
                  program.time = getSingleLine(chunk);
                  break;

                case "opplegg-1":
                  program.event = getRichText(chunk);
                  break;
              }
            }

            event.program.push(program);
          }
          break;
        }

        case "pameldingsfrist":
          event.registrationDateLimit = getDatetime(component);
          break;

        case "fremhevet":
          event.highlighted = getBoolean(component);
          break;

        case "gjester": {
          const guests = getRepeatableChunks(component);
          for (const [nameChunk, img, bio] of guests) {
            const name = (nameChunk && getSingleLine(nameChunk)) || "";
            const avatar = (img && getSingleImage(img, "raw")) || {
              img: "/images/avatar.png",
              alt: "avatar bilde",
            };

            const text = bio?.content && getRichText(bio);
            if (text) {
              event.guestsWithBio.push({
                name,
                img: avatar,
                text,
              });
            } else {
              event.guestsWithoutBio.push({
                name,
                img: avatar,
              });
            }
          }
          break;
        }

        case "trinn":
          event.levels = getLevels(component);
          break;

        case "fag":
          event.subject = getSelections(component);
          break;

        case "pameldingslink":
          event.signUpForm = getSingleLine(component);
          break;

        case "link-til-opptak-av-webinaret":
          event.videoLink = getSingleLine(component);
          break;
      }
    }
  }

  if (!event.levels.length) {
    event.levels = ["1-7", "8-10", "vgs"];
  }

  return event;
}

export function isFinished(event: EventCard): boolean {
  const lastDate = event.dates.length > 0 ? max(event.dates) : new Date();
  return isPast(lastDate);
}

export function toEventCard(event: Event): EventCard {
  return {
    id: event.id,
    title: event.title,
    slug: event.slug,
    videoLink: event.videoLink,
    website: event.website,
    cover: event.cover,
    eventType: event.eventType,
    location: event.location,
    dates: event.dates,
    levels: event.levels,
    highlighted: event.highlighted,
    description: event.description,
    subject: event.subject,
  };
}
