import type { ImageWithAltType } from "./articles";
import type { Document } from "~/types/document";
import type { Crystallize } from "~/crystallize/spec";

export const EVENT_LABELS: Record<Crystallize.EventType, string> = {
  course: "kurs",
  themed: "fagkveld",
  webinar: "webinar",
  party: "fest",
  conferance: "konferanse",
};

export const EVENT_TYPES = Object.keys(
  EVENT_LABELS,
) as ReadonlyArray<Crystallize.EventType>;

export interface Event extends Document {
  intro?: string;
  description?: string[];
  eventType: Crystallize.EventType;
  dates: Date[];
  time?: string;
  location?: Location;
  link?: string;
  guestsWithoutBio: Person[];
  guestsWithBio: Person[];
  program: ProgramEvent[];
  website?: string;
  videoLink?: string;
  registrationDateLimit?: Date;
  signUpForm?: string;
  highlighted?: boolean;
  fullBooked: boolean;
}

export type EventCard = Pick<
  Event,
  | "id"
  | "eventType"
  | "cover"
  | "levels"
  | "title"
  | "dates"
  | "location"
  | "videoLink"
  | "website"
  | "slug"
  | "highlighted"
  | "description"
  | "subject"
>;

export interface Person {
  img: ImageWithAltType;
  name: string;
  text?: string;
}

export interface ProgramEvent {
  time?: string;
  event?: string;
}

export interface Location {
  city?: string;
  address?: string;
  mapLink?: string;
}
